var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "glances-cpu-cores-wrapper" },
    _vm._l(_vm.cpuChartData, function (chartData, index) {
      return _c(
        "div",
        { key: index, staticClass: "percentage-charts" },
        [
          _c("PercentageChart", {
            attrs: { values: chartData, title: `Core #${index + 1}` },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }